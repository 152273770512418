.shimmer-container {
  display: grid;
  column-gap: 45px;
  row-gap: 45px;
  grid-template-columns: repeat(4, 1fr);
  width: 880px;
}

.member-card.shimmer {
  width: 185px;
  height: 278.777px;
  position: relative;
  box-shadow: 0px 0px 11.25px 1.266px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
  overflow: hidden;
}

.shimmer-effect {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}


.member-img.shimmer-effect {
  width: 100%;
  height: 153.263px;
  object-fit: cover;
}

.member-card-bg.shimmer-effect {
  width: 100%;
  height: 165px;
}

.member-details .shimmer-text {
  height: 14px;
  width: 60%;
  margin: 10px auto;
  border-radius: 4px;
}

.member-details .shimmer-line {
  width: 31.603px;
  height: 1.436px;
  margin: 10px auto;
  background-color: #ec028c;
  border-radius: 2px;
}

/* resources */

.resources-shimmer-box {
  width: 232.5px;
  height: 172.4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer 1.5s infinite ;
  border-radius: 4px;
}


/* casestudy */
/* casestudy */
.shimmer_case_studies {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.shimmer_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shimmer_title {
  width: 973px;
  height: 90px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer 1.5s infinite ;
  border-radius: 4px;
}

.shimmer_column {
  height: 300px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer 1.5s infinite ;
  border-radius: 4px;
}

/* About  */

/* Shimmer animation */


.shimmer-title {
  width: 100%;
  height: 30px;
  background: #f0f0f0;
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  margin-bottom: 16px;
}

.shimmer-content {
  width: 100%;
  height: 420px;
  background: #f0f0f0;
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  margin-bottom: 16px;
}
.desc-shimmer-content{
  width: 100%;
  height: 420px;
  background: #f0f0f0;
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  margin-bottom: 16px;
}
.shimmer-img{
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
}




/* Shimmer effect animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}


/* Shimmer container styles */
.shimmer-gallery-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* Shimmer item styles */
.shimmer-gallery-item {
  background-color: #f2f2f2;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 250px;
  height: 188px;
  margin-top: 60px;
}

/* Shimmer effect */
.shimmer-img,
.shimmer-title,
.shimmer-text {
  background: linear-gradient(
    90deg,
    #f0f0f0 25%,
    #e0e0e0 50%,
    #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmer-loading 1.5s infinite;
  border-radius: 4px;
}

.shimmer-img {
  height: 150px;
  width: 100%;
}

.shimmer-title {
  height: 15px;
  width: 70%;
}

.shimmer-text {
  height: 12px;
  width: 90%;
}

/* Keyframes for shimmer animation */
@keyframes shimmer-loading {
  from {
    background-position: -200% 0;
  }
  to {
    background-position: 200% 0;
  }
}
